@import "../../../common/Variable.module.scss";

.account {

    @media (max-width: 768px) {
        .account{
            overflow: hidden;
        }
      }

    h2 {
        font-weight: 700;
        font-size: 26px;
    }

    .form {
        width: 100%;
        height: 330px;
        background: $white-color;
        margin-top: 25px;
        border-radius: 20px;
        padding: 30px;

        p {
            font-size: $medium-font-size;
            text-align: start;
            padding-bottom: 10px;
            font-weight: 600;
        }

        input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            padding-left: 15px;
            font-size: $medium-font-size;
            outline: none;
            border: 1px solid $border-color;
        }

        button {
            background: $blue-color;
            color: $white-color;
            border: none;
            padding: 12px 35px;
            font-weight: 600;
            border-radius: 20px;
            margin-top: 20px;
            cursor: pointer;
            transition: transform 0.5s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }
        }
    }
}