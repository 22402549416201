@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id,
        .column-page-name,
        .column-description,
        .column-status {
            border-bottom: 1px solid $border-color;
        }

        .page-status {
            text-align: start;
            padding-left: 30px;

            .publish {
                background-color: $blue-color;
                color: $white-color;
                padding: 0px 5px;
                border-radius: 5px;
                font-weight: 600;
                font-size: $small-font-size;
                display: inline-block;
                border: none;
            }

            .unpublish {
                background-color: $red-color;
                color: $white-color;
                padding: 0px 5px;
                border-radius: 5px;
                font-weight: 600;
                font-size: $small-font-size;
                display: inline-block;
                border: none;
            }
        }

        .column-id {
            height: 70px;
            padding-left: 50px;
        }
        .column-page-name {
            padding-left: 30px;
        }
        .column-description {
            padding-left: 30px;
        }
        .column-action {
            border-bottom: 1px solid $border-color;
        }

        .action-icons {
            display: flex;
            text-align: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;

            .user-icon {
                color: $blue-color;
                font-size: $large-font-size;
            }
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            width: 30%;
            padding: 10px 30px;
            border-bottom: 1px solid $border-color;

            svg {
                margin-left: 25px;
            }
        }
    }
    .no-data-message{
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}