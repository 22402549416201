@import "../../common/Variable.module.scss";

.sidebar {
    width: 260px;
    background-color: $blue-color;
    padding: 0px 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    overflow-y: auto;

    @media(max-width: 768px) {
        margin-left: -260px;
        transition: margin-left 0.8s ease;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .logo {
        width: 260px;
        background-color: $blue-color;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        border-bottom: 1px solid $dark-gray-color;
        padding: 30px 20px;
        z-index: 1000;

        &.open {
            @media(max-width: 768px) {
                margin-left: 0;
            }
        }

        img {
            max-width: 100%;
            width: 100%;
        }

        @media(max-width: 768px) {
            margin-left: -260px;
            transition: margin-left 0.8s ease;
        }
    }

    .category_list {
        padding: 15px;
        overflow-y: auto;
        margin-top: 60px;

        ul {
            list-style-type: none;
            padding: 0;

            .sidebar_links {

                &.interest_clicked {
                    color: $yellow-color;

                    .icon_and_link svg {
                        color: $dark-color;
                        background: $yellow-color;
                        border-radius: 5px;
                    }

                    a {
                        color: $yellow-color;
                    }
                }

                &.submenu_clicked {
                    .submenu {
                        li {
                            a {
                                font-size: $medium-font-size;
                            }

                            &.active {
                                a {
                                    color: $yellow-color;
                                }
                            }
                        }
                    }
                    .icon_and_link svg {
                        color: $dark-color;
                        background: $yellow-color;
                        border-radius: 5px;
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 20px;
                    font-size: $medium-font-size;
                    font-weight: 600;
                    color: $light-color;
                    cursor: pointer;
                    white-space: nowrap;

                    .icon_and_link {
                        display: flex;
                        align-items: center;

                        svg {
                            font-size: 30px;
                            padding: 5px;
                            transition: color 0.3s ease-in-out;

                            &:hover {
                                background: $yellow-color;
                                color: $dark-color;
                                padding: 5px;
                                border-radius: 5px;
                            }
                        }

                        span {
                            margin-left: 15px;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: $light-color;
                        margin-left: 15px;
                        transition: color 0.3s ease-in-out;

                        &:hover {
                            color: $yellow-color;
                        }
                    }
                }

                .chevron_right {
                    margin-left: auto;
                    font-size: 30px;
                    padding: 5px;
                }

                .chevron_down {
                    margin-left: auto;
                    font-size: 30px;
                    padding: 5px;
                }
            }
        }
    }
}