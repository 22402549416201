@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id,
        .column-image,
        .column-title,
        .column-status {
            border-bottom: 1px solid $border-color;
        }

        .column-id {
            height: 70px;
            padding-left: 40px;
        }

        .column-image {
            text-align: start;

            @media (max-width:1360px) {
                padding-left: 10px;
            }

            @media (max-width:1200px) {
                padding-left: 20px;
            }

            .language_image {
                width: 100%;
                padding-left: 30px;

                img {
                    height: 50px;
                    // width: 40%;
                    object-fit: contain; 
                }
            }
        }

        .column-title {
            padding-left: 30px;
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            width: 25%;
            padding: 10px 30px;
            border-bottom: 1px solid $border-color;
            text-align: start;

            svg {
                margin-left: 25px;
            }
        }

        .status-published {
            background-color: $blue-color;
            color: $white-color;
            padding: 0px 5px;
            margin-left: 30px;
            border-radius: 5px;
            font-weight: 600;
            font-size: $small-font-size;
            display: inline-block;
            border: none;
        }

        .status-unpublished {
            background-color: $red-color;
            color: $white-color;
            padding: 0px 5px;
            margin-left: 30px;
            border-radius: 5px;
            font-weight: 600;
            font-size: $small-font-size;
            display: inline-block;
            border: none;
        }

        .column-action {
            border-bottom: 1px solid $border-color;
            padding-left: 20px;
        }

        .action-icons {
            display: flex;
            text-align: center;
            justify-content: start;
            gap: 8px;
            cursor: pointer;

            .pencil-icon {
                color: $blue-color;
                font-size: $large-font-size;
            }

            .trash-icon {
                color: $red-color;
                font-size: $large-font-size;
            }
        }
    }

    .no-data-message {
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}

.delete_message {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    border: 2px solid $blue-color;
    border-radius: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: $white-color;
    box-shadow: $box-shadow-color;
    z-index: 200;
    transition: opacity 0.5s ease-in-out;

    @media (max-width:1995px) {
        left: 60%;
        max-width: 400px;
        top: 12%;
    }

    @media (max-width:1500px) {
        left: 60%;
        max-width: 400px;
        top: 14%;
    }

    @media (max-width:873px) {
        left: 65%;
        top: 14%;
    }

    @media (max-width:768px) {
        left: 48%;
        top: 14%;
    }

    @media (max-width:475px) {
        left: 50%;
        top: 14%;
        max-width: 300px;
    }
}

p {
    text-align: center;
    padding: 15px 10px;
    font-size: $medium-font-size;
    width: 100%;
}