@import "../../../common/Variable.module.scss";

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.settings {
    overflow: auto;

    .card {
        width: 100%;
        height: fit-content;
        background: $white-color;
        border-radius: 20px;
        padding: 30px;
        margin-top: 30px;

        .header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $dark-color;
            padding-bottom: 15px;
            gap: 5px;

            h4 {
                font-weight: 500;
            }
        }

        .form {
            display: flex;
            gap: 40px;
            flex-wrap: wrap;

            .name {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 25px;
                flex: 0 0 240px;
                width: calc(25% - 20px);

                @media (max-width: 1450px) {
                    width: calc(33.33% - 20px);
                }

                @media (max-width: 1159px) {
                    width: calc(50% - 20px);
                }

                @media (max-width: 480px) {
                    width: calc(100% - 20px);
                }

                .topLine {
                    display: flex;
                    align-items: center;
                    letter-spacing: 1px;
                    gap: 5px;

                    svg {
                        color: $red-color;
                        font-size: 5px;
                    }

                    h5 {
                        font-weight: 500;
                        color: $gray-color2;
                    }
                }

                input {
                    margin-top: 10px;
                    padding-left: 15px;
                    width: 250px;
                    height: 50px;
                    border-radius: 5px;
                    outline: none;
                    font-weight: 500;
                    border: 1px solid $border-color;

                    @media (max-width: 480px) {
                        width: auto;
                    }
                }

                .error{
                    color: $red-color;
                    font-size: $small-font-size;
                }

                .fileInputContainer {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    border: 1px solid $border-color;
                    border-radius: 5px;
                    width: 250px;

                    @media (max-width: 480px) {
                        width: auto;
                    }

                    input {
                        display: none;
                    }

                    label {
                        background-color: $light-gray-color;
                        color: $dark-color;
                        padding: 15px 10px 5px 5px;
                        height: 50px;
                        cursor: pointer;

                        &:hover {
                            background: $dark-gray-color;
                        }
                    }

                    span {
                        margin-left: 10px;
                    }
                }

                p {
                    font-size: 13.50px;
                    margin-left: 5px;
                    margin-top: 5px;
                }

                .imagePreview {
                    width: 100px;
                    height: auto;
                    margin-top: 10px;
                }

                label {
                    font-weight: 500;
                    color: $gray-color2;
                    font-size: 13.5px;
                }

                select {
                    margin-top: 10px;
                    padding: 0px 10px;
                    width: 250px;
                    height: 50px;
                    border-radius: 5px;
                    outline: none;
                    font-weight: 500;
                    border: 1px solid $border-color;
                    appearance: none;

                    @media (max-width: 480px) {
                        width: auto;
                    }
                }

            }
        }
    }

    .signal_info {

        .signal_header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $dark-color;
            padding-bottom: 15px;
            margin-top: 30px;

            svg {
                margin-top: 4px;
            }

            h4 {
                margin-left: 5px;
                font-weight: 500;
            }
        }

        .user_info {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: space-between;

            .app_id {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 35px;
                flex: 1 1 calc(50% - 20px);

                @media (max-width: 1020px) {
                    flex: 1 1 100%;
                    margin-bottom: 20px;
                }

                @media (max-width: 480px) {
                    width: 100%;
                    flex: 1 1 100%;
                }

                .topLine {
                    display: flex;
                    align-items: center;
                    letter-spacing: 1px;
                    width: 100%;

                    svg {
                        color: $red-color;
                        font-size: 5px;
                        margin-right: 5px;
                    }

                    h5 {
                        font-weight: 500;
                        color: $gray-color2;
                    }
                }

                input {
                    margin-top: 10px;
                    padding-left: 15px;
                    width: 100%;
                    height: 50px;
                    border-radius: 5px;
                    outline: none;
                    font-weight: 500;
                    border: 1px solid $border-color;
                    

                    @media (max-width: 480px) {
                        width: 100%;
                    }
                }
                .error{
                    color: $red-color;
                    font-size: $small-font-size;
                    margin-top: 5px;
                }
            }
        }

        button {
            background: $blue-color;
            color: $white-color;
            border: none;
            padding: 12px 35px;
            font-weight: 600;
            border-radius: 20px;
            cursor: pointer;
            transition: transform 0.5s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }
        }
    }
}

.rotatingIcon {
    animation: rotate 2s linear infinite;
}