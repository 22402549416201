@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;


    .datatable {
        min-width: 50rem;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: auto;

        ::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            background-color: $gray-color;
        }

        ::-webkit-scrollbar-track {
            background: $gray-color;
        }

        // ::-webkit-scrollbar-thumb {
        //     background: $white-color; 
        //     border-radius: 30px; 
        //     height: 0px;
        // }

        .column-id {
            height: 60px;
            padding: 20px;
        }

        .column-id,
        .column-title,
        .column-amount,
        .column-limit,
        .column-filter,
        .column-direct-chat,
        .column-chat,
        .column-like-menu,
        .column-audio-video,
        .column-status {
            border-bottom: 1px solid $border-color;
            padding-left: 20px;
        }

        .column-id{
            padding-left: 40px;
        }

        .column-status {
            .status-publish {
                background-color: $blue-color;
                color: $white-color;
                padding: 0px 5px;
                border-radius: 5px;
                font-weight: 600;
                font-size: $small-font-size;
                display: inline-block;
            }

            .status-unpublish {
                background-color: $red-color;
                color: $white-color;
                padding: 0px 5px;
                border-radius: 5px;
                font-weight: 600;
                font-size: $small-font-size;
                display: inline-block;
                border: none;
            }
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            width: 15%;
            height: 40px;
            padding: 20px;
            text-align: start;
            border-bottom: 1px solid $border-color;

            svg {
                margin-left: 25px;
            }
        }

        .column-action {
            border-bottom: 1px solid $border-color;
        }

        .action-icons {
            display: flex;
            text-align: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;

            .user-icon {
                color: $blue-color;
                font-size: $large-font-size;
            }
        }
    }

    .no-data-message {
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}