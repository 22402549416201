@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id,
        .column-firstname,
        .column-report-user,
        .column-comment {
            border-bottom: 1px solid $border-color;
        }

        .column-id {
            height: 70px;
            padding-left: 40px;
        }
        .column-firstname{
            padding-left: 35px;
        }
        .column-report-user{
            padding-left: 35px;
        }
        .column-comment{
            padding-left: 30px;
        }
        .column-date {
            border-bottom: 1px solid $border-color;
            padding-left: 30px;
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            width: 25%;
            padding: 10px 30px;
            border-bottom: 1px solid $border-color;
            text-align: start;

            svg {
                margin-left: 25px;
            }
        }
    }
    .no-data-message{
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}