@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id {
            width: 50px;
            height: 70px;
            padding-left: 30px;
            border-bottom: 1px solid $border-color;
            text-align: start;
        }

        .column-title {
            width: 200px;
            border-bottom: 1px solid $border-color;
            padding-left: 20px;
            text-overflow: ellipsis;
        }

        .column-subtitle {
            width: 450px;
            text-align: start;
            padding-left: 27px;
            border-bottom: 1px solid $border-color;
            text-overflow: ellipsis;
        }

        .column-status {
            width: 200px;
            padding-left: 35px;
            text-align: start;
            border-bottom: 1px solid $border-color;
        }

        .column-action {
            width: 100px;
            border-bottom: 1px solid $border-color;
        }

        .title-text {
            width: 200px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 20px;
            line-height: 1.5em;

            // @media (min-width: 350px) and (max-width:1535px) {
            //     width: 100px;
            //     padding-right: 30px;
            // }
        }

        .subtitle-text {
            width: 450px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5em;

            // @media (min-width: 350px) and (max-width:1535px) {
            //     width: 250px;
            // }

            @media (min-width: 1994px) {
                width: 600px;
            }
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            padding: 0px 30px;
            text-align: center;

            svg {
                margin-left: 25px;
            }
        }

        .status-published {
            background-color: $blue-color;
            color: $white-color;
            padding: 0px 5px;
            border-radius: 5px;
            font-weight: 600;
            font-size: $small-font-size;
            display: inline-block;
            border: none;
        }

        .status-unpublished {
            background-color: $red-color;
            color: $white-color;
            padding: 0px 5px;
            border-radius: 5px;
            font-weight: 600;
            font-size: $small-font-size;
            border: none;
        }

        .action-icons {
            display: flex;
            text-align: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;

            .pencil-icon {
                color: $blue-color;
                font-size: $large-font-size;
            }

            .trash-icon {
                color: $red-color;
                font-size: $large-font-size;
            }
        }
    }
    .no-data-message{
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}

.delete_message {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border: 2px solid $blue-color;
    max-width: 500px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: $white-color;
    box-shadow: $box-shadow-color;
    z-index: 200;
    transition: opacity 0.5s ease-in-out;

    @media (max-width:1995px) {
        left: 60%;
        max-width: 400px;
        top: 12%;
    }

    @media (max-width:873px) {
        left: 65%;
        top: 14%;
    }

    @media (max-width:768px) {
        left: 48%;
        top: 14%;
    }

    @media (max-width:475px) {
        left: 50%;
        top: 14%;
        max-width: 300px;
    }
}

p {
    text-align: center;
    padding: 15px 10px;
    font-size: $mini-large-font-size;
    width: 100%;
}