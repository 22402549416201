@import "../../../common/Variable.module.scss";

.payment {

    // entry section
    .section {
        width: 100%;
        background: $white-color;
        padding: 30px;
        border-radius: 20px;
        margin: 30px 0;

        .user_entry {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 823px) {
                flex-wrap: wrap;
            }

            .entries {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;

                p {
                    font-size: $medium-font-size;
                    border: 1px solid $border-color;
                    margin: 0px 10px;
                    width: 60px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                }
            }

            .search {
                position: relative;

                @media (max-width: 823px) {
                    flex-basis: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                }

                input {
                    width: 180px;
                    height: 35px;
                    border-radius: 10px;
                    border: 1px solid $border-color;
                    outline: none;
                    margin-left: 10px;
                    padding: 0px 10px;
                    font-size: $medium-font-size;

                    @media (max-width: 823px) {
                        width: 100%;
                        margin-top: 10px;
                        width: 140px;
                    }

                    &:focus {
                        outline: none;
                        border-color: $red-color;
                        box-shadow: $box-shadow-color2;
                    }
                }

                .closeIcon {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                    color: $blue-color;

                    @media (max-width:823px) {
                        position: relative;
                        top: 12px;
                        right: 5%;
                    }

                    @media (max-width:486px) {
                        right: 7%;
                    }
                }
            }
        }

        .user_info {
            width: 100%;
            margin: 20px 0;
            border: 1px solid $border-color;
            border-bottom: none;
        }

        .pagination {
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                background: $blue-color;
                border: none;
                color: $white-color;
                padding: 5px 8px;
                border-radius: 5px;
                cursor: pointer;
                font-size: $medium-font-size;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background: $yellow-color;
                    color: $dark-color;
                }

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                    background: $dark-gray-color;
                }

                &:active {
                    background: $blue-color;
                    color: $white-color;
                }
            }
        }
    }
}