@import "../../../common/Variable.module.scss";

.add_plan {
    .section {
        width: 100%;
        background: $white-color;
        border-radius: 20px;
        padding: 30px;
        margin: 30px 0;

        .main {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
            width: 100%;

            >div {
                width: 32%;
                margin-bottom: 15px;

                @media (max-width: 416px) {
                    margin-bottom: 0px;
                }
                
                label {
                    font-weight: 500;

                    @media (max-width: 416px) {
                        white-space: nowrap;
                    }
                }

                input {
                    width: 100%;
                    height: 40px;
                    outline: none;
                    border-radius: 5px;
                    border: 1px solid $border-color;
                    margin: 10px 0 15px 0;
                    padding-left: 20px;
                    font-size: $medium-font-size;

                    @media (max-width: 416px) {
                        padding-left: 10px;
                    }
                }
            }
        }

        .textarea {
            label {
                font-weight: 500;
            }

            textarea{
                width: 100%;
                height: 150px;
                outline: none;
                border-radius: 5px;
                border: 1px solid $border-color;
                margin: 10px 0 15px 0;
                padding-left: 20px;
                padding-top: 10px;
                font-size: $medium-font-size;
            }
        }

        .plan_option {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 80px;

            @media (max-width:1440px) {
                gap:30px;
            }

            >div {
                label {
                    font-weight: 500;
                    display: inline-block;
                    width: 150px;
                }

                .circle1 {
                    margin: 20px 0;
                    position: relative;
                    width: 45px;
                    height: 23px;
                    border-radius: 30px;
                    border: 1px solid $blue-color;
                    background: $white-color;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                }

                .circle2 {
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    left: 8%;
                    border-radius: 50%;
                    background: $light-gray-color;
                    cursor: pointer;
                    transition: left 0.3s ease, background 0.3s ease;

                    &.toggled{
                        left: calc(100% - 2px - 20px);
                        background: $blue-color;
                    }
                }
            }
        }
        label{
            font-weight: 500;
        }
        select {
            width: 100%;
            height: 40px;
            outline: none;
            border-radius: 5px;
            border: 1px solid $border-color;
            margin: 10px 0 15px 0;
            padding-left: 20px;
            font-size: $medium-font-size;
            appearance: none;
        }
    }

    button {
        background: $blue-color;
        color: $white-color;
        border: none;
        padding: 12px 35px;
        font-weight: 600;
        border-radius: 20px;
        margin-top: 10px;
        cursor: pointer;
        transition: transform 0.5s ease-in-out;

        &:hover {
            background: $yellow-color;
            color: $dark-color;
        }
    }
}
.update_message {
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border: 2px solid $blue-color;
    max-width: 500px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: $white-color;
    box-shadow: $box-shadow-color;
    z-index: 200;
    transition: opacity 0.5s ease-in-out;
   
    @media (max-width:1995px) {
        left: 60%;
        max-width: 400px;
        top: 12%;
    }

    @media (max-width:1500px) {
        left: 60%;
        max-width: 400px;
        top: 14%;
    }

    @media (max-width:873px) {
        left: 65%;
        top: 14%;
    }

    @media (max-width:768px) {
        left: 48%;
        top: 14%;
    }

    @media (max-width:475px) {
        left: 50%;
        top: 14%;
        max-width: 300px;
    }
}

p {
    text-align: center;
    padding: 15px 10px;
    font-size: $mini-large-font-size;
    width: 100%;
}