@import "../../../common/Variable.module.scss";

.dashboard {

    .dashboard_card {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px; 
        margin-top: 25px;

        @media (max-width: 1313px) {
            grid-template-columns: repeat(3, minmax(0, 1fr)); 
        }

        @media (max-width: 1095px) {
            grid-template-columns: repeat(2, minmax(0, 1fr)); 
        }

        @media (max-width: 856px) {
            grid-template-columns: 1fr; 
        }

        .card {
            display: flex;
            align-items: center;
            justify-content: space-between; 
            height: 140px;
            border-radius: 20px;
            background: $white-color;
            padding: 20px;
            
            .title {
                padding: 10px;
                flex-shrink: 0;

                h3 {
                    display: flex;
                    align-items: center;
                    color: $blue-color;
                    justify-content: center;
                    background: $light-color;
                    font-size: $small-font-size;
                    font-weight: 600;
                    padding: 4px 20px;
                    border-radius: 10px;
                    white-space: nowrap;
                    transition: all 0.3 ease-in-out;

                    &:hover {
                        background: $yellow-color;
                    }
                }

                h2 {
                    font-size: $large-font-size;
                    margin-top: 10px;
                    margin-left: 10px;
                }
            }

            .image {
                padding: 10px;
                flex-shrink: 0;

                img {
                    height: 60px;
                    width: 60px;
                    object-fit: cover; 
                }
            }
        }
    }
}