@import "../../common/Variable.module.scss";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: $white-color;

    .login_error_message{
        position: fixed;
        top: 12%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        border: 2px solid $red-color;
        max-width: 500px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        opacity: 0;
        background-color: $white-color;
        box-shadow: $box-shadow-color;
        z-index: 200;
        transition: opacity 0.5s ease-in-out;

        @media (max-width: 568px) {
            width: 85%;
        }

        p {
            color: $red-color;
            text-align: center;
            padding: 15px 10px;
            font-size: $mini-large-font-size;
            width: 100%;
        }

        &.success {
            border-color: 2px solid $green-color;
            color: $green-color;
        }
    
        &.error {
            border-color: 2px solid $red-color;
            color: $red-color;
        }
    }

    .login {
        width: 500px;
        border-radius: 5px;
        background: $light-gray-color;
        padding: 30px;
        color: $dark-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        @media (max-width: 568px) {
            width: 85%;
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            img {
                height: 60px;

                @media (max-width: 421px) {
                    height: 40px;
                }
            }
        }

        p {
            text-align: center;
            padding-bottom: 10px;
            color: $blue-color;
            font-weight: 600;

            @media (max-width: 555px) {
                font-size: $small-font-size;
            }
        }

        .input_icon {
            position: relative;

            svg {
                font-size: $large-font-size;
                color: $blue-color;
                position: absolute;
                top: 40%;
                left: 2%;
            }

            svg:nth-of-type(1) {
                left: 2%;
            }

            svg:nth-of-type(2) {
                right: 2%;
                left: auto;
            }

            input {
                width: 100%;
                height: 5vh;
                background: $white-color;
                border: none;
                outline: none;
                margin-top: 10px;
                border-radius: 5px;
                color: $dark-color;
                font-size: $medium-font-size;
                padding-left: 40px;

                @media (max-width: 555px) {
                    font-size: $small-font-size;
                }
            }

            .eye_icon {
                cursor: pointer;
            }
        }

        .error_message {
            color: $red-color;
            font-size: $small-font-size;
            position: absolute;
            bottom: -20px;
            left: 0;
        }

        .error_messages {
            color: $red-color;
            position: absolute;
        }

        .forgot_pass {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                margin-top: 5px;
                cursor: pointer;
                color: $blue-color;
                text-decoration: underline;
                font-weight: 600;

                @media (max-width: 904px) {
                    margin-top: 10px;
                }
                @media (max-width: 555px) {
                    font-size: $small-font-size;
                }
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            background: $blue-color;
            border: none;
            margin-top: 10px;
            border-radius: 5px;
            color: $light-color;
            font-size: $medium-font-size;
            cursor: pointer;
            transition: background 0.3s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }

            @media (max-width: 555px) {
                font-size: $medium-font-size;
            }

            @media (max-width: 421px) {
                margin-top: 0px;
            }
        }
    }
}