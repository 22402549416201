@import "../../../common/Variable.module.scss";

.user_info {
    .section {
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 30px 0px;

        @media (max-width:1523px) {
            flex-direction: column;
        }

        .profile_image {
            width: 35%;

            @media (max-width:1523px) {
                width: 100%;
            }

            .user_profile {
                width: 100%;
                height: auto;
                border-radius: 15px;
                background: $white-color;

                @media (max-width:1523px) {
                    width: 100%;
                }

                p {
                    text-align: start;
                    font-weight: 600;
                    padding: 20px 0px 20px 20px;
                    border-bottom: 1px solid $border-color;
                    cursor: pointer;
                }

                .user_image {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 20px 0px 20px 20px;

                    img {
                        height: 80px;
                        width: 80px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    h5 {
                        color: $blue-color;
                        margin-left: 20px;
                        font-size: $medium-font-size;
                    }
                }
            }

            .payment_info {
                position: relative;
                height: auto;
                width: 100%;
                border-radius: 15px;
                background: $white-color;
                margin-top: 30px;
                padding: 10px;

                @media (max-width:1523px) {
                    width: 100%;
                }

                p {
                    text-align: start;
                    font-weight: 600;
                    padding: 15px 0px 15px 15px;
                    cursor: pointer;
                }

                img {
                    height: 70px;
                    width: 70px;
                    border-radius: 50%;
                    margin: 10px;
                    object-fit: cover;
                }
            }

            .plan_info {
                height: auto;
                border-radius: 15px;
                background: $white-color;
                margin-top: 30px;
                padding: 12px;

                @media (max-width:1523px) {
                    width: 100%;
                }

                .plan_button {
                    display: flex;
                    align-items: center;

                    p {
                        font-weight: 600;
                        text-align: start;
                    }

                    // button {
                    //     background: $blue-color;
                    //     color: $white-color;
                    //     border-radius: 5px;
                    //     border: none;
                    //     cursor: pointer;
                    //     padding: 5px 10px;
                    //     white-space: nowrap;
                    // }
                }

                .plan_date {
                    display: flex;
                    align-items: center;
                    gap: 90px;
                    margin-bottom: 20px;

                    @media (max-width:475px) {
                        flex-wrap: wrap;
                        gap: 30px;
                    }

                    >div {
                        p {
                            font-weight: 600;
                            white-space: nowrap;
                        }

                        h6 {
                            color: $gray-color;
                            font-size: $medium-font-size;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        .other_info {
            width: 65%;
            height: auto;
            background: $white-color;
            border-radius: 15px;
            padding: 30px 20px;

            @media (max-width:1523px) {
                width: 100%;
            }

            .header {

                @media (max-width:1523px) {
                    line-height: 20px;
                }

                @media (max-width:768px) {
                    line-height: 10px;
                }

                .info_item {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    @media (max-width:860px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    @media (max-width:768px) {
                        flex-direction: row;
                        align-items: center;
                    }

                    @media (max-width:642px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    h4 {
                        width: 40%;

                        @media (max-width:860px) {
                            text-align: start;
                            padding-left: 0;
                            width: 100%;
                        }
                    }

                    p {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: 60%;
                        color: $gray-color;
                        text-align: start;
                        font-size: $medium-font-size;

                        @media (max-width:860px) {
                            text-align: start;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .main {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-bottom: 30px;

        @media (max-width:1523px) {
            flex-wrap: wrap;
        }

        .user_interest {
            width: 50%;
            background: $white-color;
            border-radius: 15px;
            padding: 10px;

            @media (max-width:1523px) {
                width: 100%;
            }

            p {
                font-weight: 600;
                text-align: start;
            }

            .userContainer {
                display: flex;
                align-items: center;
                gap: 30px;

                @media (max-width: 920px) {
                    flex-wrap: wrap;
                }

                .interestItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media (max-width: 537px) {
                        justify-content: center;
                        flex-basis: 25%;
                    }

                    @media (max-width: 453px) {
                        justify-content: center;
                        flex-basis: 36%;
                    }
                    @media (max-width: 421px) {
                        justify-content: center;
                        flex-basis: 40%;
                    }

                    img {
                        height: 60px;
                        width: 60%;
                        object-fit: contain;
                    }

                    p {
                        font-weight: 600;
                        text-align: center;
                    }
                }
            }
        }

        .user_language {
            width: 50%;
            background: $white-color;
            border-radius: 15px;
            padding: 10px;

            @media (max-width:1523px) {
                width: 100%;
            }

            p {
                font-weight: 600;
                text-align: start;
            }

            .languageContainer {
                display: flex;
                align-items: center;
                gap: 30px;

                @media (max-width:460px) {
                    flex-wrap: wrap;
                }

                .languageItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        height: 60px;
                        width: 60px;
                    }

                    p {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}