@import "../../../common/Variable.module.scss";

.user {

    // entry section
    .section {
        width: 100%;
        background: $white-color;
        padding: 30px;
        border-radius: 20px;
        margin: 30px 0;

        .user_entry {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 894px) {
                flex-wrap: wrap;
            }

            .entries {
                display: flex;
                align-items: center;
                justify-content: center;

                select {
                    padding: 0px 7px;
                    height: 35px;
                    border-radius: 10px;
                    border: 1px solid $border-color;
                    margin: 0px 10px;
                    font-size: $medium-font-size;
                    outline: none;
                }
            }

            .search_section {
                display: flex;
                align-items: center;
                gap: 10px;

                @media (max-width: 894px) {
                    flex-basis: 100%;
                    margin-top: 10px;
                    justify-content: flex-start;
                }
                @media (max-width: 417px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0;
                }

                .search {
                    position: relative;
                    display: flex;
                    align-items: center;
                    
                    input {
                        width: 180px;
                        height: 35px;
                        border-radius: 10px;
                        border: 1px solid $border-color;
                        outline: none;
                        margin-left: 10px;
                        padding: 0px 10px;
                        font-size: $medium-font-size;

                        @media (max-width: 894px) {
                            width: 140px;

                        }

                        &:focus {
                            outline: none;
                            border-color: $red-color;
                            box-shadow: $box-shadow-color2;
                        }
                    }

                    .closeIcon {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        cursor: pointer;
                        color: $blue-color;
                    }
                }
            }

            button {
                background: $blue-color;
                color: $light-color;
                border: none;
                height: 35px;
                border-radius: 10px;
                cursor: pointer;
                transition: 0.3s ease-in-out;

                &:hover {
                    background: $yellow-color;
                    color: $dark-color;
                }
                @media (max-width: 417px) {
                    margin-top: 15px; 
                  }
            }
        }

        .user_info {
            width: 100%;
            margin: 20px 0;
            border: 1px solid $border-color;
            border-bottom: none;
        }

        .pagination {
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                background: $blue-color;
                border: none;
                color: $white-color;
                padding: 5px 8px;
                border-radius: 5px;
                cursor: pointer;
                font-size: $medium-font-size;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background: $yellow-color;
                    color: $dark-color;
                }

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                    background: $dark-gray-color;
                }

                &.active {
                    background: $yellow-color;
                    color: $dark-color;
                }

            }
        }
    }
}