@import "../../../common/Variable.module.scss";

/* CustomModal.module.scss */
@keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes modalFadeOut {
    from {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -55%);
    }
  }

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: $light-gray-color;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
    max-width: 80%;
    outline: none;
    animation: modalFadeIn 0.3s forwards;

    @media (max-width:980px) {
      left: 65%;
    }
    @media (max-width:768px) {
      left: 50%;
    }
  }

  .modal.fade-out {
    animation: modalFadeOut 0.3s forwards;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p{
        font-weight: 600;
        color: $gray-color2;
        font-size: $medium-font-size;
    }

    .modalActions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        gap: 10px;
      }
  }
  
   button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .modalActions button:first-of-type {
    background-color: $dark-gray-color;
    color: #333;
  }
  
  .modalActions button:last-of-type {
    background-color: $red-color;
    color: $white-color;
  }
  