@import "../../common/Variable.module.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $white-color;

    .popup_message {
        position: fixed;
        top: 12%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        border: 2px solid ;
        max-width: 500px;
        border-radius: 10px;
        display: flex;
        opacity: 0;
        align-items: center;
        background-color: $white-color;
        box-shadow: $box-shadow-color;
        z-index: 200;
        transition: opacity 0.5s ease-in-out;

        &.visible {
            opacity: 1;
            visibility: visible;
        }

        @media (max-width: 568px) {
            width: 85%;
        }

        p {
            text-align: center;
            padding: 15px 10px;
            font-size: $medium-font-size;
            width: 100%;

            @media (max-width: 537px) {
                font-size: $small-font-size;
            }
        }

        &.success {
            border-color: 2px solid $green-color;
            color: $green-color;
        }
    
        &.error {
            border-color: 2px solid $red-color;
            color: $red-color;
        }
    }

    .resetPasswordPage {
        width: 500px;
        border-radius: 5px;
        background: $light-gray-color;
        padding: 30px;
        color: $dark-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        @media (max-width: 568px) {
            width: 85%;
        }

        h2 {
            color: $blue-color;
            text-align: center;
            margin-bottom: 20px;
        }

        .formGroup {
            margin-bottom: 20px;
            position: relative;

            label {
                font-weight: 600;
            }

            .inputWrapper {
                display: flex;
                align-items: center;
                position: relative;

                input {
                    width: 100%;
                    padding: 10px;
                    padding-left: 40px;
                    border-radius: 5px;
                    font-size: $medium-font-size;
                    margin-top: 10px;
                    outline: none;
                    border: none;
                }

                .icon {
                    position: absolute;
                    left: 10px;
                    top: 32%;
                    font-size: $large-font-size;
                    color: $blue-color;
                }

                .eyeIcon {
                    position: absolute;
                    right: 10px;
                    cursor: pointer;
                    font-size: $large-font-size;
                    color: $blue-color;
                    bottom: 5px;
                }
            }

            .error_message {
                position: absolute;
                color: $red-color;
                font-size: $small-font-size;
                text-align: center;
            }

            .error_input {
                border-color: $red-color;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            background: $blue-color;
            border: none;
            border-radius: 5px;
            color: $light-color;
            font-size: $medium-font-size;
            margin-top: 30px;
            cursor: pointer;
            transition: background 0.3s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }

            @media (max-width: 768px) {
                font-size: $medium-font-size;
            }
        }
    }
}