// theme Color 
$blue-color: #0d3b66;
$cream-color: #faf0ca;
$yellow-color: #f4d35e;
$light-color: #ebf2fa;
$dark-color:#000000;
$light-gray-color: #dfe8ec;
$dark-gray-color: #b0c6cf;
$border-color: #d0e0e2;
$white-color:#FFFFFF;
$red-color: #ff0000;
$gray-color: #7f8c8d;
$gray-color2: #626768;
$green-color: #008000;
$box-shadow-color:1px 1px 5px 5px rgba(128, 128, 128, 0.322);
$box-shadow-color2: 0 0 0 2px rgba(255, 0, 0, 0.3);

// Define Font-size Variable
$small-font-size: 12px;
$medium-font-size: 15px;
$mini-large-font-size: 18px;
$large-font-size: 20px;
$extra-large-font-size: 24px;