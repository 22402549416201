@import "../../common/Variable.module.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $white-color;

    .otp_error_message {
        position: fixed;
        top: 12%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        border: 2px solid;
        max-width: 500px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        opacity: 0;
        background-color: $white-color;
        box-shadow: $box-shadow-color;
        z-index: 200;
        transition: opacity 0.5s ease-in-out;

        @media (max-width: 568px) {
            width: 85%;
        }

        p {
            text-align: center;
            padding: 15px 10px;
            font-size: $medium-font-size;
            width: 100%;

            @media (max-width: 537px) {
                font-size: $small-font-size;
            }
        }

        &.success {
            border: 2px solid $green-color;
            color: $green-color;
        }
    
        &.error {
            border: 2px solid $red-color;
            color: $red-color;
        }
    }
    

    .main_icon{
        text-align: center;
        font-size: 50px;
        color: $blue-color;
    }

    .otpPage {
        width: 500px;
        border-radius: 5px;
        background: $light-gray-color;
        padding: 30px;
        color: $dark-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        @media (max-width: 568px) {
            width: 85%;
        }

        h3 {
            color: $blue-color;
            text-align: center;
            margin-bottom: 20px;
        }

        .inputEmail {
            margin-bottom: 20px;
            position: relative;

            input {
                width: 100%;
                padding: 10px;
                padding-left: 40px;
                border: none;
                outline: none;
                border-radius: 5px;
                font-size: $medium-font-size;
                box-sizing: border-box;
            }

            .icon {
                position: absolute;
                left: 10px;
                color: $blue-color;
                font-size: $large-font-size;
                top: 8px;
            }
        }
        .otpInputs {
            display: flex;
            justify-content: center;
            gap: 10px; 
            margin-bottom: 20px;

            input {
                width: 40px;
                height: 40px; 
                font-size: $large-font-size; 
                text-align: center;
                border: 1px solid $border-color;
                border-radius: 5px;
                outline: none;
                border: none;
                padding: 0;
                box-sizing: border-box;

                &:focus {
                    border-color: $blue-color; 
                }
            }

            .error_input {
                border-color: $red-color;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            background: $blue-color;
            border: none;
            border-radius: 5px;
            color: $light-color;
            font-size: $medium-font-size;
            margin-top: 40px;
            cursor: pointer;
            transition: background 0.3s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }

            @media (max-width: 768px) {
                font-size: $medium-font-size;
            }
        }

        .error_message {
            position: absolute;
            color: $red-color;
            font-size: $small-font-size;
        }
    }
}
