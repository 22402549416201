@import "../../common/Variable.module.scss";

.navbar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $white-color;
    width: calc(100% - 260px);
    height: 70px;
    position: fixed;
    top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    @media(max-width: 768px) {
        width: 100%;
        left: 0;
    }

    // &.light {
    //     background-color: $white-color;
    //     color: #1c2129;
    // }

    // &.dark {
    //     background-color: #1c2129;
    //     color: $white-color;
    // }

    .user_info {
        display: flex;
        align-items: center;
        font-size: $extra-large-font-size;
        margin-left: auto;

        svg {
            margin-right: 15px;
            cursor: pointer;
            box-sizing: content-box;
            padding: 5px;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
                border-radius: 5px;
                padding: 5px;
            }

            svg:last-child {
                margin-right: 0;
            }
        }

        .profile {
            position: relative;
            display: flex;

            .barIcon {
                display: none;
                margin-left: 10px;
                margin-top: 3px;
                font-size: $large-font-size;

                @media(max-width: 768px) {
                    display: block;
                    margin-left: 0;
                }
            }

            .dropdown_menu {
                position: absolute;
                top: 40px;
                right: 10px;
                background-color: $light-color;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                overflow: hidden;
                opacity: 0;
                transform: translateY(20px);
                transition: opacity 0.3s ease, transform 0.3s ease;
                display: none;

                .dropdown_item {
                    padding: 5px 20px;
                    // border: 1px solid $border-color;
                    font-size: $medium-font-size;
                    color: $dark-color;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    white-space: nowrap;

                    &:hover {
                        background-color: $light-gray-color;
                    }

                    svg {
                        font-size: $large-font-size;
                        margin-right: 10px;
                    }
                }
            }

            .dropdown_menu {
                opacity: 1;
                transform: translateY(0);
                display: block;
            }
        }
    }
}