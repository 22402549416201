@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id,
        .column-amount,
        .column-coin,
        .column-username,
        .column-transfer_detail,
        .column-transfer_type,
        .column-user_mobile,
         .column-status,
         .column-action{
            border-bottom: 1px solid $border-color;
            padding-left: 30px;
        }

        .column-id {
            height: 70px;
        }

        .p-datatable {
            width: 100%;
        }

        .column-header-small {
            width: 25%;
            padding: 10px 20px;
            border-bottom: 1px solid $border-color;

            svg {
                margin-left: 25px;
            }
        }
    }

    .no-data-message{
        text-align: center;
        margin: 5px 0;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}