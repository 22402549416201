@import "../../common/Variable.module.scss";

.content_div {
  margin-left: 260px;
  margin-top: 70px;
  overflow: hidden;
  background: $light-color;

  @media (max-width:768px) {
    margin-top: 70px;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width:360px) {
    padding: 10px 15px 5px 15px;
  }

  .content {
    height: calc(100vh - 70px);
    padding: 20px 30px 10px 30px;
    overflow: auto;
  }
}