    @import "../../../common/Variable.module.scss";

    .add_interest {
        .section {
            width: 100%;
            background: $white-color;
            border-radius: 20px;
            padding: 30px;
            margin-top: 30px;

            label {
                font-weight: 500;
            }

            .fileInputContainer {
                display: flex;
                align-items: center;
                margin-top: 10px;
                border: 1px solid $border-color;
                border-radius: 5px;
                width: 100%;

                @media (max-width: 480px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media (max-width: 590px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media (max-width: 850px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                input {
                    display: none;
                }

                label {
                    background-color: $light-gray-color;
                    color: $dark-color;
                    height: 35px;
                    cursor: pointer;
                    padding: 5px 10px;

                    &:hover {
                        background: $dark-gray-color;
                    }

                    @media (max-width: 480px) {
                        width: 100%;
                    }

                    @media (max-width: 590px) {
                        width: 100%;
                    }

                    @media (max-width: 850px) {
                        width: 100%;
                    }
                }

                span {
                    margin-left: 10px;

                    @media (max-width: 480px) {
                        height: 40px;
                    }

                    @media (max-width: 590px) {
                        height: 40px;
                    }

                    @media (max-width: 850px) {
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            input[type="text"] {
                width: 100%;
                height: 40px;
                outline: none;
                border-radius: 5px;
                border: 1px solid $border-color;
                margin-top: 10px;
                padding-left: 20px;
                font-size: $medium-font-size;
            }

            select {
                width: 100%;
                max-width: 100%;
                height: 40px;
                outline: none;
                border-radius: 5px;
                border: 1px solid $border-color;
                margin-top: 10px;
                padding-left: 20px;
                font-size: $medium-font-size;
                appearance: none;
            }
        }

        .error {
            color: $red-color;
            text-align: start;
            font-size: $small-font-size;
            padding: 0;
            margin-left: 5px;
        }

        button {
            background: $blue-color;
            color: $white-color;
            border: none;
            padding: 12px 35px;
            font-weight: 600;
            border-radius: 20px;
            margin-top: 20px;
            cursor: pointer;
            transition: transform 0.5s ease-in-out;

            &:hover {
                background: $yellow-color;
                color: $dark-color;
            }
        }
    }

    .update_message {
        position: fixed;
        top: 12%;
        left: 55%;
        transform: translateX(-50%);
        width: 100%;
        border: 2px solid $blue-color;
        max-width: 500px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        opacity: 0;
        background-color: $white-color;
        box-shadow: $box-shadow-color;
        z-index: 200;
        transition: opacity 0.5s ease-in-out;

        @media (max-width:1995px) {
            left: 60%;
            max-width: 400px;
            top: 12%;
        }

        @media (max-width:1500px) {
            left: 60%;
            max-width: 400px;
            top: 14%;
        }

        @media (max-width:873px) {
            left: 65%;
            top: 14%;
        }

        @media (max-width:768px) {
            left: 48%;
            top: 14%;
        }

        @media (max-width:475px) {
            left: 50%;
            top: 14%;
            max-width: 300px;
        }

        p {
            text-align: center;
            padding: 15px 10px;
            font-size: $mini-large-font-size;
            width: 100%;
        }
    }