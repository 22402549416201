@import "../../../../common/Variable.module.scss";

.main {
    overflow-x: auto;

    .datatable {
        min-width: 50rem;

        .column-id,
        .column-name,
        .column-email,
        .column-mobile,
        .column-create-date,
        .column-status,
        .column-action {
            border-bottom: 1px solid $border-color;
        }

        .column-id {
            height: 60px;
            padding-left: 40px;
        }

        .column-name {
            padding-left: 20px;
        }
        .column-email{
            padding-left: 20px;
        }

        .column-mobile {
            padding-left: 20px;
        }

        .column-create-date {
            padding-left: 20px;
        }
        .column-status {
            padding-left: 20px;
        }

        .column-header-small {
            width: 15%;
            padding: 0 20px;
            text-align: center;

            svg {
                margin-left: 25px;
            }
        }

        .action-icons {
            display: flex;
            padding-left: 35px;
            cursor: pointer;

            .user-icon {
                color: $blue-color;
                font-size: $large-font-size;
            }

            .trash-icon {
                color: $red-color;
                font-size: $large-font-size;
            }
        }
    }

    .no-data-message {
        text-align: center;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        .p-datatable {
            width: 100%;
        }
    }
}

.delete_message {
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    border: 2px solid $blue-color;
    border-radius: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: $white-color;
    box-shadow: $box-shadow-color;
    z-index: 200;
    transition: opacity 0.5s ease-in-out;

    @media (max-width:1995px) {
        left: 60%;
        max-width: 400px;
        top: 12%;
    }

    @media (max-width:1500px) {
        left: 60%;
        max-width: 400px;
        top: 14%;
    }

    @media (max-width:873px) {
        left: 65%;
        top: 14%;
    }

    @media (max-width:768px) {
        left: 48%;
        top: 14%;
    }

    @media (max-width:475px) {
        left: 50%;
        top: 14%;
        max-width: 300px;
    }
}

p {
    text-align: center;
    padding: 15px 10px;
    font-size: $mini-large-font-size;
    width: 100%;
}